/* eslint-disable react-hooks/exhaustive-deps */
//Icons

//Styles

import React, { useEffect, useState } from "react";
import walletIcon from "../../../Assets/images/wallet.png";

import Back from "../../../shared/components/Back";
import "./loanIndividualDetails.scss";
//Libraries
import { Button, Col, Drawer, Popover, Row, Tabs } from "antd";
import { TabsProps } from "../../../shared/types/Tabs.type";

import { LoanService } from "../../../services/Loan/loan.service";
import { useLocation, useNavigate } from "react-router";
import LoanSummary from "./LoanSummary";
import { useParams } from "react-router-dom";
import LoanRepaymentSchedule from "./LoanRepaymentSchedule";
import LoanTimeline from "./LoanTimeline";
import LoanWallet from "./LoanWallet";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import dotIcon from "../../../Assets/images/dotIcon.png";
import { LoanSeeding } from "../../../enums/LoanSeeding.type";
import useDrawer from "../../../shared/hooks/useDrawer";
import PreCloseLoan from "./LoanRepaymentSchedule/PreCloseLoan";
import RegenerateRepayment from "./LoanRepaymentSchedule/RegenerateRepayment";
import { LoanSeedingType } from "../../../shared/types/loanSeeding.type";
import { Loan } from "../../../models/loan.model";
import LoanInstallment from "./LoanInstallment";
import { hasAccessOf } from "../../../shared/utils/hasAccess";
import { RoleName } from "../../../enums/roleName.enum";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { compareRouteAndPath } from "../../../shared/utils/compareRouteAndPath";
import Comment from "../../Customers/CustomerDetail/Comments";
import { connectToChannel } from "../../../shared/utils/connectToChannel";
import { ChannelName } from "../../../enums/channelName.type";
import { useReportContext } from "../../../context/ReportContext";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { Employee } from "../../../models/employee.model";
import { organisationNames } from "../../../enums/organisationNames";
import { BCStatusTags } from "../../../enums/currentStatusTags.type";
import CustomModal from "../../../shared/components/CustomModal";
import { Formik } from "formik";
import { LoanAgreement } from "../../../models/loanAgreement.model";
import { loanAgreementValidation } from "../../BranchAccountant/LoanAgreementValidation";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { CloseCircleOutlined } from "@ant-design/icons";
import UgroDocumentUploadDrawer from "../../BranchAccountant/UGRODocumentUpload";
import { generatePath } from "react-router";
import { VivitriDetails } from "../../../models/vivitriDetails.model";
import UploadLoanAgreement from "./UploadLoanAgreement";
import { UploadLoanAgreementConstants } from "./UploadLoanAgreement/constants";
import { LoanIndividualDetailsConstants } from "./constants";

const LoanindividualDetails = () => {
  //Service
  const location = useLocation();
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;
  const isClaims =  location.pathname.includes('loan-claims')
  const isBranchAccountant = employee?.roleName === RoleName.BRANCH_ACCOUNTANT
  const isLoanSection = location?.pathname.includes(AppRoutes.LOANS);
  const isCustomerLoan = compareRouteAndPath(
    AppRoutes.CUSTOMERS_LOAN_DETAILS,
    location.pathname
  );
  const { reportData, updateReportData } = useReportContext();
  const navigate = useNavigate()
  const { updateParams } = useQueryParams({
    params: {},
  });
  const { getLoan, loading, templateLoading, generateLoanReportCard, loanAgreementUpload, getVivitriLoanDetails } =
    LoanService();
  const {
    visible: repaymentScheduleSettingsVisible,
    toggleVisibility: toggleRepaymentScheduleSettings,
  } = useDrawer({});
  const {
    visible: repaymentWalletVisible,
    toggleVisibility: toggleRepaymentWalletSettings,
  } = useDrawer({});

  const {
    visible: uploadLoanAgreementVisible,
    toggleVisibility: toggleUploadLoanAgreementVisible,
  } = useDrawer({});
  //Context
  const { loanId = "" } = useParams<{ loanId: string }>();

  //State
  const [loan, setLoan] = useState(new Loan());
  const [walletAmount, setWalletAmount] = useState<number>();
  const [isPopupVisible, setIsPopoupVisible] = useState(false);
  const [isLoanChange, setIsLoanChange] = useState(false);
  const [loanAgreementModal, setLoanAgreementModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(isClaims ? "6" : "1");
  const [customerLoan, ] = useState<boolean>(isCustomerLoan);
  const [vivitriDetails, setVivitriDetails] = useState(new VivitriDetails())
  const validOrganizations: string[] = [organisationNames.MASS_FINANCIAL_SERVICES, organisationNames.UGRO];
  const isMASOrUgro = validOrganizations.includes(loan?.organizationName);
  const [selectedLoanSeeding, setCurrentLoanSeeding] =
    useState<LoanSeedingType>();
  const {
    visible: loanSeedingVisible,
    toggleVisibility: toggleLoanSeedingForm,
  } = useDrawer({});

  useEffect(() => {
    initializeLoan();
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, []);

  const initializeLoan = async () => {
    const loan = await getLoan(Number(loanId));
    if (loan?.organizationName === organisationNames.VCL) {
      const data = await getVivitriLoanDetails(loanId);
      setVivitriDetails(data ?? new VivitriDetails())
    }
    setLoan(loan ?? new Loan());
  };

  const {
    visible: ugroDocumentDrawerVisible,
    toggleVisibility: ugroDocumentDrawerVisibility,
  } = useDrawer({});

  const handleLoanAgreementModal = () => {
    if ((loan?.organizationName === organisationNames.MASS_FINANCIAL_SERVICES) || (loan?.organizationName === organisationNames.VCL))
      setLoanAgreementModal(!loanAgreementModal);
    else if (loan?.organizationName === organisationNames.UGRO)
      ugroDocumentDrawerVisibility(true);
  }

  const toggleUploadLoanAgreement = () => {
    toggleUploadLoanAgreementVisible()
  }
  
  const handleWalletBalance = (balance: number) => setWalletAmount(balance);
  
  const handleOnClose = () => {
    initializeLoan();
    toggleLoanSeedingForm(false);
    setIsLoanChange(!isLoanChange);
  };

  const handleDownloadReport = async () => {
    await connectToChannel(
      process.env.REACT_APP_REPORT_CHANNEL_URL as string,
      ChannelName.VELICHAM,
      {
        onConnected: async () => await generateLoanReportCard(String(loanId)),
      },
      reportData,
      updateReportData
    );
  };

  const tabs: TabsProps[] = customerLoan
    ? [
        {
          label: "Loan Details",
          key: "1",
          component: <LoanSummary vivitriDetails={vivitriDetails} loan={loan} loanLoad={loading} initializeLoan={initializeLoan} />,
          hash: "#personal-details",
        },
        {
          label: "Installment Schedule",
          key: "5",
          component: <LoanInstallment />,
        },
      ]
    : [
        {
          label: "Loan Details",
          key: "1",
          component: (
            <LoanSummary
              vivitriDetails={vivitriDetails}
              initializeLoan={initializeLoan}
              loan={loan}
              loanLoad={loading}
              onSuccess={initializeLoan}
            />
          ),
          hash: "#personal-details",
        },
        {
          label: "Repayment Schedule",
          key: "2",
          component: (
            <LoanRepaymentSchedule
              loan={loan}
              settingsVisible={repaymentScheduleSettingsVisible}
              onSettings={toggleRepaymentScheduleSettings}
              isLoanChange={isLoanChange}
            />
          ),
          hash: "#repayment-schedule",
          condition: !isBranchAccountant
        },
        {
          label: "Wallet",
          key: "3",
          component: (
            <LoanWallet
              settingsVisible={repaymentWalletVisible}
              onSettings={toggleRepaymentWalletSettings}
              handleWalletBalance={handleWalletBalance}
            />
          ),
          hash: "#wallet",
          condition: !isBranchAccountant
        },
        {
          label: "Comments",
          key: "4",
          component: (
            <Comment currentLoan={loan} allComments={loan.comments} customer={loan?.customer} commentLoanId={loanId} />
          ),
          hash: "#comments",
        },
        {
          label: "Timeline",
          key: "5",
          component: <LoanTimeline />,
          hash: "#timeline",
        },
      ];

  const loanSeedingOptions: LoanSeedingType[] = [
    {
      label: "Pre Close Loan",
      value: LoanSeeding.PRE_CLOSE_LOAN,
      component: <PreCloseLoan loan={loan} onClose={handleOnClose} />,
    },
    // {
    //   label: "Write Off",
    //   value: LoanSeeding.WRITE_OFF,
    //   component: <WriteOff loan={loan} onClose={handleOnClose} />,
    // },
    // {
    //   label: "Moratorium",
    //   value: LoanSeeding.MORATORIUM,
    //   component: <Moratorium loan={loan} onClose={handleOnClose} />,
    // },
    {
      label: "Regenerate Repayment",
      value: LoanSeeding.REGENERATE_REPAYMENT,
      component: <RegenerateRepayment loan={loan} onClose={handleOnClose} />,
    },
  ];
  const isBranchManager = hasAccessOf(RoleName.BRANCH_ACCOUNTANT)
  const isLoanApproved = loan?.currentStatusTag === BCStatusTags.LOAN_AGREEMENT_UPLOADED
  const isLoanAgreementUploadInProgress = loan?.currentStatusTag === BCStatusTags.LOAN_AGREEMENT_UPLOAD_IN_PROGRESS
  const isVClApproved = loan?.currentStatusTag === BCStatusTags.VCL_AGREEMENT_UPLOADED
  const isVclLoan = loan?.organizationName === organisationNames.VCL
  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab) => tab?.key === activeKey)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(activeKey);
  };

  const handleCreditReportClick = () => {
    navigate(generatePath(AppRoutes.INDIVIDUAL_CUSTOMER_CREDIT_REPORT, {
        loanId: String(loan?.id),
      })
    )
  }

  const getTabExtraContent = () => {
    switch (activeTab) {
      case "1":
        return (
          <div className="loan-section-buttons">
          {isLoanSection && (
            <div className="loan-report-buttons">
              <Button
                className="btn-primary loan-disburse-btn report-button"
                htmlType="submit"
                onClick={handleDownloadReport}
                loading={templateLoading}
              >
                DOWNLOAD REPORT
              </Button>
            </div>
          )}
           <Button onClick={handleCreditReportClick} disabled={loading} className="credit-report-btn mr-3 loan-disburse-btn">
              Credit Report
            </Button>
            {((isBranchManager && !isLoanAgreementUploadInProgress && isMASOrUgro && !isLoanApproved)) && (
            <Button className="btn-primary mr-5 verify-btn loan-agreement-button" onClick={handleLoanAgreementModal}>
              Upload Document
            </Button>
          )}
          {((!isVClApproved && !isMASOrUgro && isBranchAccountant)) && (
            <Button className="btn-primary mr-5 verify-btn loan-agreement-button" onClick={toggleUploadLoanAgreement}>{isVclLoan ? UploadLoanAgreementConstants.UPLOAD_DOCUMENTS : UploadLoanAgreementConstants.TITLE}</Button>
          )}
        </div>
        );
      case "2":
        return (
          <div className="extra-content">
            <TabExtraContent
              searchProps={{
                onSearch: () => {},
                placeholder: "",
                isActive: false,
              }}
              settingsClick={toggleRepaymentScheduleSettings}
            />

            {!hasAccessOf(RoleName.HEAD_OFFICER) && userAccess?.writeAccess && (
              <Popover
                className="ml-5"
                open={isPopupVisible}
                placement="bottomLeft"
                content={
                  <div>
                    {loanSeedingOptions?.map((loan) => (
                      <div
                        className="cursor-pointer mb-2 "
                        onClick={() => {
                          setCurrentLoanSeeding(loan);
                          toggleLoanSeedingForm();
                          setIsPopoupVisible(false);
                        }}
                      >
                        {loan?.label}
                      </div>
                    ))}
                  </div>
                }
                trigger="click"
              >
                <p className="text-link" onClick={() => setIsPopoupVisible(!isPopupVisible)}>
                  <img alt={LoanIndividualDetailsConstants.IMAGE_ALT} src={dotIcon} className="dot-icon icon-container " />
                </p>
              </Popover>
            )}
          </div>
        );
      case "3":
        return (
          <div className="d-flex">
            <div className="wallet-balance">
              <Button className="wallet-btn mr-5">
                <Row gutter={[30, 0]} align="middle">
                  <Col span={3}>
                    <img src={walletIcon} alt="Wallet Icon" className="mt-2" />
                  </Col>
                  <Col span={4}>
                    <span>
                      Balance in Wallet:{" "}
                      <span className="size-20 text-bold">₹{walletAmount}</span>
                    </span>
                  </Col>
                </Row>
              </Button>
            </div>
            {/* <div className="extra-content">
              <TabExtraContent
                searchProps={{
                  onSearch: () => {},
                  placeholder: "",
                  isActive: false,
                }}
                settingsClick={toggleRepaymentWalletSettings}
              />
            </div>{" "} */}
          </div>
        );
    }
  };

  return (
    <div className="loan-individual-details">
      <div className="loan-individual-details__header mb-5">
        <Back />
      </div>
      <div className="loan-individual-details__content">
        <Tabs
          activeKey={String(activeTab)}
          onChange={handleTabChange}
          tabBarExtraContent={{
            right: getTabExtraContent(),
          }}
        >
          {tabs?.filter(({ condition }) => condition !== false).map(({ component, label, key }) => (
            <Tabs.TabPane tab={label} key={key}>
              {component}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      <Formik
        validationSchema={loanAgreementValidation}
        initialValues={new LoanAgreement()}
        onSubmit={()=>{}}
      >
        {({ setFieldValue, isValid, dirty, values, resetForm, errors }) => { 

          const handleLoanAgreementUpload = async () => {
            const loanId = loan?.id
            const loanValues = {...values, loanId}
            const result = await loanAgreementUpload(loanValues)
            if (result) {
            setLoanAgreementModal(false)
            navigate(AppRoutes.BRANCH_ACCOUNTANT_HOME)
            }
          }

          const closeForm = () => {
            setLoanAgreementModal(false)
            resetForm()
          }

          return (
        <CustomModal
          title="Upload Loan Agreement"
          visible={loanAgreementModal}
          onCancel={closeForm}
          showCancel={false}
          okText="Upload"
          onOk={handleLoanAgreementUpload}
          >
          <Col span={24}>
            <AttachmentUpload
              accept="image/*,application/pdf"
              name="loanAgreementId"
              placeholder="Upload File"
              setFieldValue={setFieldValue}
              isLoading={(value) => setIsLoading(value)}
            />
            {values.loanAgreementId && !isLoading && (
              <Col className="mt-5" span={8}>
                <span className="text-primary mr-5">
                  File Uploaded
                </span>
                <CloseCircleOutlined
                  onClick={() =>
                    setFieldValue("loanAgreementId", undefined)
                  }
                />
              </Col>
            )}
          </Col>
        </CustomModal>
        )}
      }
      </Formik>
      <Drawer
        placement="right"
        onClose={() => toggleLoanSeedingForm(false)}
        visible={loanSeedingVisible}
        width={600}
        title={selectedLoanSeeding?.label}
        destroyOnClose
      >
        {selectedLoanSeeding?.component}
      </Drawer>
      <Drawer
      destroyOnClose
      title="Upload Documents"
      width="850"
      onClose={()=> ugroDocumentDrawerVisibility(false)}
      open={ugroDocumentDrawerVisible}
      >
      <UgroDocumentUploadDrawer loan={loan} onClose={() => ugroDocumentDrawerVisibility(false)}/>
      </Drawer>
      <UploadLoanAgreement isVclLoan={isVclLoan} refetch={initializeLoan} visible={uploadLoanAgreementVisible} toggleVisibility={toggleUploadLoanAgreementVisible} loan={loan}/>
    </div>
  );
};

export default LoanindividualDetails;
