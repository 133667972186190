
import { alias, list, object, serializable } from "serializr";
import { Loan } from "../loan.model";

export class Disbursement {
    @serializable(alias("batch_code"))
    batchCode?: string;

    @serializable(list(object(Loan)))
    loans?: Loan[]
    
}